<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  type="text"
                  :label="$t('Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">{{
                $t("Company")
              }}</label>
              <MultiSelectInput
                v-model="form.company"
                :options="customers"
                label="companyName"
                trackBy="id"
                :key="form.company"
                moduleName="customers"
                :multiple="false"
                ><template #option="{ props }">
                  <div>
                    <p>
                      {{ props.option.companyNumber }} -
                      {{ props.option.companyName }}
                    </p>
                  </div>
                </template>
              </MultiSelectInput>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">
                {{ $t("Roles") }}</label
              >
              <multi-select
                v-model="form.roles"
                :multiple="true"
                label="title"
                track-by="id"
                :options="roles"
              />
            </div>
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">
                {{ $t("Status") }}</label
              >

              <select class="input-form form-control" v-model="form.status">
                <option value="1">
                  {{ $t("Active") }}
                </option>
                <option value="0">
                  {{ $t("Inactive") }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="input-label form-label" for="cars">
                {{ $t("Type") }}</label
              >
              <multi-select
                v-model="form.type"
                :multiple="false"
                label="label"
                track-by="value"
                :options="keyTypes"
              />
              <!-- <select class="input-form form-control" v-model="form.type">
                <option :value="1">
                  {{ $t("Owner") }}
                </option>
                <option :value="2">
                  {{ $t("Partner") }}
                </option>
                <option :value="3">
                  {{ $t("Customer") }}
                </option>
              </select> -->
            </div>
          </div>
          <div class="row"></div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="primary"
          @click="update"
          class="d-flex align-items-center gap-1"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update API Key") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";
export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    ValidationObserver,
    MultiSelectInput,
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    ...mapGetters("customers", ["customers"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Api Keys"),
          to: "/api-keys",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return !this.form.name;
    },
  },
  data() {
    return {
      form: {
        name: "",
        company: "",
        type: "",
        status: "",
        roles: [],
      },
      keyTypes: [
        { value: "owner", label: this.$t("Owner") },
        { value: "partner", label: this.$t("Partner") },
        { value: "customer", label: this.$t("Customer") },
      ],
    };
  },

  async created() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("customers/list");
      await this.$store.dispatch("roles/list", {
        limit_start: 0,
        limit_count: 100,
      });
      const response = await this.$store.dispatch(
        "apikeys/show",
        this.$route.params.id
      );
      console.log("response", response);
      this.form = {
        name: response?.data[0]?.name ?? "",
        status: response?.data[0]?.status ?? 0,
        type:
          this.keyTypes.find(
            (el) => el.value == (response?.data[0]?.type ?? "")
          ) ?? "",
        roles: response?.data[0]?.roles
          .map((roleId) => {
            return {
              ...(this.roles.find((role) => role.id == roleId) ?? {}),
            };
          })
          .filter((role) => role.id),
      };
      if (response?.data?.[0]?.company_id) {
        const companyId = response.data[0].company_id;
        this.form.company =
          this.customers.find((customer) => customer.id === companyId) || {};
      }
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },

  methods: {
    async update() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            this.$store.commit("showLoader", true);
            await this.$store.dispatch("apikeys/update", {
              id: this.$route.params.id,
              ...this.form,
              set_company_id: this.form.company?.id ?? "",
              roles:
                this.form.roles && this.form.roles.length
                  ? this.form.roles.map((role) => role.id)
                  : [],
            });
            this.$router.push("/api-keys");
          } catch (e) {
            console.error("api error ___", e);
          } finally {
            this.$store.commit("showLoader", false);
          }
        }
      });
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
